import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import store from'@/store'
const routes = [
  // Autentikasi
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
    meta: { guest: true }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register.vue"),
    meta: { guest: true }
  },
  // End Autentikasi
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
    meta: { guest: true } 
  },
  // Modul Administrasi
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { auth: true }
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { auth: true }
  },

  {
    path: "/user-dan-hak-akses",
    name: "User Dan Hak Akses",
    component: () => import("../views/master/UserHakAkses.vue"),
    meta: { auth: true },
  },

  {
    path: "/tambah-user-dan-hak-akses",
    name: "Tambah User Dan Hak Akses",
    component: () => import("../views/master/TambahUserHakAkses.vue"),
    meta: { auth: true },
  },

  {
    path: "/data-log-activity",
    name: "Data Log Activity",
    component: () => import("../views/master/DataLogActivity.vue"),
    meta: { auth: true },
  },

  {
    path: "/edit-user-dan-hak-akses/:id",
    name: "Edit User Dan Hak Akses",
    component: () => import("../views/master/EditUserHakAkses.vue"),
    meta: { auth: true },
  },

  {
    path: "/setting-klinik",
    name: "Setting Klinik",
    component: () => import("../views/master/SettingKlinik.vue"),
    meta: { auth: true },
  },
  {
    path: "/setting-user",
    name: "Setting User",
    component: () => import("../views/master/SettingAkun.vue"),
    meta: { auth: true },
  },

  {
    path: "/setup-Cabang",
    name: "Setup Cabang",
    component: () => import("../views/master/SetupCabang.vue"),
    meta: { auth: true },
  },

  {
    path: "/edit-data-Cabang/:id",
    name: "Edit Cabang",
    component: () => import("../views/master/EditDataCabang.vue"),
    meta: { auth: true },
  },

  {
    path: "/tambah-data-cabang",
    name: "Tambah Data Cabang",
    component: () => import("../views/master/TambahDataCabang.vue"),
    meta: { auth: true },
  },

  {
    path: "/tindakan",
    name: "Tindakan",
    component: () => import("../views/master/Tindakan.vue"),
    meta: { auth: true },
  },
  {
    path: "/data-tindakan",
    name: "Data Tindakan",
    component: () => import("@/views/master/DataTindakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-tindakan-lab",
    name: "Data Tindakan lab",
    component: () => import("@/views/master/DataTindakanLab.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-tindakan-kecantikan",
    component: () => import("@/views/master/DataTindakanKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-tindakan",
    name: "Tambah Data Tindakan",
    component: () => import("@/views/master/TambahDataTindakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-tindakan-kecantikan",
    name: "Tambah Data Tindakan Kecantikan",
    component: () => import("@/views/master/TambahDataTindakanKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-tindakan-lab",
    name: "Tambah Data Tindakan Lab",
    component: () => import("@/views/master/TambahDataTindakanLab.vue"),
    meta: { auth: true }
  },
  {
    path: "/odontogram",
    name: "Odontogram",
    component: () => import("@/views/pelayanan/components/Odontogram.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-perawatan-gigi/:id",
    name: "Tambah Data Perawatan Gigi",
    component: () => import("@/views/pelayanan/TambahDataPerawatanGigi.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-perawatan-kia/:id",
    name: "Tambah Data Perawatan KIA",
    component: () => import("@/views/pelayanan/TambahDataPerawatanKia.vue"),
    meta: { auth: true }
  },
   {
    path: "/tambah-data-perawatan-khitan/:id",
    name: "Tambah Data Perawatan Khitan",
    component: () => import("@/views/pelayanan/TambahDataPerawatanKhitan.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-perawatan-ugd/:id",
    name: "Tambah Data Perawatan Ugd Siang",
    component: () => import("@/views/pelayanan/TambahDataPerawatanUgdSiang.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-perawatan-ugd-malam/:id",
    name: "Tambah Data Perawatan Ugd Malam",
    component: () => import("@/views/pelayanan/TambahDataPerawatanUgdMalam.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-perawatan-kecantikan/:id",
    name: "Tambah Data Perawatan kecantikan",
    component: () => import("@/views/pelayanan/TambahDataPerawatanKecantikan.vue"),
    meta: { auth: true }
   },

  {
    path: "/edit-data-tindakan/:id",
    name: "Edit Data Tindakan",
    component: () => import("@/views/master/EditDataTindakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-tindakan-lab/:id",
    name: "Edit Data Tindakan lab",
    component: () => import("@/views/master/EditDataTindakanLab.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-tindakan-kecantikan/:id",
    name: "Edit Data Tindakan Kecantikan", 
    component: () => import("@/views/master/EditDataTindakanKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/isi-data-paket/:id",
    name: "Isi Data Paket",
    component: () => import("@/views/master/IsiDataPaket.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-voucher",
    name: "Data Voucher",
    component: () => import("@/views/master/DataVoucher.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-voucher/:id",
    name: "Edit Data Voucher",
    component: () => import("@/views/master/EditDataVoucher.vue"),
    meta: { auth: true }
  },


  {
    path: "/administrasi",
    name: "administrasi",
    component: () => import("../views/master/Administrasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-administrasi",
    name: "Data Administrasi",
    component: () => import("@/views/master/DataAdministrasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-administrasi",
    name: "Tambah Data Administrasi",
    component: () => import("@/views/master/TambahDataAdministrasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-administrasi/:id",
    name: "Edit Data Administrasi",
    component: () => import("@/views/master/EditDataAdministrasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-jasa-pemeriksaan",
    name: "Data Jasa Pemeriksaan",
    component: () => import("@/views/master/DataPemeriksaan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-jasa",
    name: "Tambah Data Jasa",
    component: () => import("@/views/master/TambahDataJasa.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-jasa/:id",
    name: "Edit Data Jasa",
    component: () => import("@/views/master/EditDataJasa.vue"),
    meta: { auth: true }
  },
  {
    path: "/uang-duduk",
    name: "Uang Duduk",
    component: () => import("@/views/master/UangDuduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-uang-duduk",
    name: "Tambah Uang Duduk",
    component: () => import("@/views/master/TambahUangDuduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/jabatan",
    component: () => import("@/views/master/Jabatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/jabatan-detail",
    component: () => import("@/views/master/component/JabatanDetail.vue"),
    meta: { auth: true }
  },
  {
    path: "/level-detail",
    component: () => import("@/views/master/component/LevelJabatanDetail.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-jabatan",
    component: () => import("@/views/master/TambahJabatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-jabatan/:id",
    component: () => import("@/views/master/EditJabatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-level-jabatan",
    component: () => import("@/views/master/TambahLevelJabatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/poli",
    component: () => import("@/views/master/Poli.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-poli",
    component: () => import("@/views/master/TambahPoli.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-poli/:id",
    component: () => import("@/views/master/EditPoli.vue"),
    meta: { auth: true }
  },
  {
    path: "/layanan",
    component: () => import("@/views/master/Layanan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-layanan",
    component: () => import("@/views/master/TambahLayanan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-layanan/:id",
    component: () => import("@/views/master/EditLayanan.vue"),
    meta: { auth: true }
  },
  {
    path: "/jadwal-shift",
    component: () => import("@/views/master/JadwalShift.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-jadwal-shift",
    component: () => import("@/views/master/TambahJadwalShift.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-jadwal-shift/:id",
    component: () => import("@/views/master/EditJadwalShift.vue"),
    meta: { auth: true }
  },
  {
    path: "/pergantian-shift",
    component: () => import("@/views/master/PergantianShift.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pergantian-shift",
    component: () => import("@/views/master/LaporanPergantianShift.vue"),
    meta: { auth: true }
  },
  // End Modul Administrasi
  
  // Modul Rekam Medis
  {
    path: "/data-pasien",
    name: "Data Pasien",
    component: () => import("@/views/rekam_medis/DataPasien.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-pasien/:id",
    name: "Edit Data Pasien",
    component: () => import("@/views/rekam_medis/EditDataPasien.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-pasien-kecantikan/:id",
    name: "Edit Data Pasien kecantikan",
    component: () => import("@/views/rekam_medis/components/EditDataPasienKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekam-medis",
    name: "Rekam Medis",
    component: () => import("@/views/rekam_medis/RekamMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekam-medis-detail/:id",
    name: "Rekam Medis Detail",
    component: () => import("@/views/rekam_medis/RekamMedisDetail.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekam-medis-detail-kecantikan/:id",
    name: "Rekam Medis Detail Kecantikan",
    component: () => import("@/views/rekam_medis/RekamMedisDetailKct.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-rekam-medis/:id",
    name: "Edit Rekam Medis",
    component: () => import("@/views/rekam_medis/components/EditDataRekamMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/view-odontogram/:id",
    name: "View Odontogram",
    component: () => import("@/views/rekam_medis/components/ViewOdontogram.vue"),
    meta: { auth: true }
  },
  {
    path: "/show-resiko-jatuh/:id",
    name: "Show Resiko Jatuh",
    component: () => import("@/views/rekam_medis/components/ShowResikoJatuh.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-rekam-medis/:id",
    name: "Print_Rekam_Medis",
    component: () => import("@/views/rekam_medis/components/Print_RekamMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-rujukan/:id",
    name: "Print_Rujukan",
    component: () => import("@/views/pelayanan/PrintRujukan.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-rujukan-ugd/:id",
    name: "Print Rujukan UGD",
    component: () => import("@/views/pelayanan/PrintRujukanUgd.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-rujukan-gigi/:id",
    name: "Print Rujukan Gigi",
    component: () => import("@/views/pelayanan/PrintRujukanGigi.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-rekam-medis-kct/:id",
    name: "Print_Rekam_Medis_Kct",
    component: () => import("@/views/rekam_medis/components/Print_RekamMedisKct.vue"),
    meta: { auth: true }
  },
  {
    path: "/persetujuan-satu-sehat/:id",
    name: "Persetujuan Satu Sehat",
    component: () => import("@/views/rekam_medis/components/PersutujanSatuSehat.vue"),
    meta: { auth: true }
  },
  {
    path: "/show-histori-kunjungan/:id",
    name: "Histori Kunjungan",
    component: () => import("@/views/rekam_medis/components/ShowHistoriKunjungan.vue"),
    meta: { auth: true }
  },
  {
    path: "/show-resume-medis/:id",
    name: "Resume Medis",
    component: () => import("@/views/rekam_medis/components/ShowResumeMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekam-medis-detail-lab/:id",
    name: "Rekam Medis Detail Lab",
    component: () => import("@/views/rekam_medis/RekamMedisDetailLab.vue"),
    meta: { auth: true }
  },
  {
    path: "/mapping-wilayah",
    name: "Mapping Wilayah",
    component: () => import("@/views/rekam_medis/MappingWilayah.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-pasien-kecantikan",
    component: () => import("@/views/rekam_medis/components/DataPasienKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-formulir",
    name: "Data Formulir",
    component: () => import("@/views/rekam_medis/data_formulir.vue"),
    meta: { auth: true }
  }, 
{
     path: "/detail-hak-dan-kewajiban/:id",
     name: "Detail Hak Dan Kewajiban",
     component: () => import("@/views/rekam_medis/detail_hak_kewajiban.vue"),
     meta: { auth: true }
    },
    {
      path: "/detail-lab/:id",
      name: "Detail Pemeriksaan Lab",
      component: () => import("@/views/rekam_medis/detail_lab.vue"),
      meta: { auth: true }
     },
    {
      path: "/edit-data-pasien-resepsionis/:id",
      name: "Edit Data Pasien Resepsionis",
      component: () => import("@/views/rekam_medis/Editdtpasien.vue"),
      meta: { auth: true }
     },
    {
      path: "/data-log-edit/:id",
      name: "Data Log Edit",
      component: () => import("@/views/rekam_medis/DataLogEdit.vue"),
      meta: { auth: true }
     },
   {
     path: "/detail-informed-concent/:id",
     name: "Detail Informed Concent",
     component: () => import("@/views/rekam_medis/detail_informedconcent.vue"),
     meta: { auth: true }
    },
   {
     path: "/detail-edukasi/:id",
     name: "Detail detail edukasi",
     component: () => import("@/views/rekam_medis/detail_edukasi.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-print-hak-dan-kewajiban/:id",
     name: "Data hak dan kewajiban Rekam medis",
     component: () => import("@/views/pelayanan/DataPrintHakdankewajibanrme.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-print-informedconcent/:id",
     name: "Data Print Informed Concent",
     component: () => import("@/views/pelayanan/DataPrintInformedconcent.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-print-edukasi/:id",
     name: "Data Print Edukasi KIA",
     component: () => import("@/views/pelayanan/DataPrintedukasi.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-lab-kimia-klinik/:id",
     name: "Data Lab Kimia Klinik",
     component: () => import("@/views/pelayanan/DataLabKimiaKlinik.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-lab-hematologi-rutin/:id",
     name: "Data Lab Hematologi Rutin",
     component: () => import("@/views/pelayanan/DataLabHematologiRutin.vue"),
     meta: { auth: true }
    },
   {
     path: "/kunjungan-today",
     name: "Data Kunjungan Hari Ini",
     component: () => import("@/views/pelayanan/Kunjungan_Today.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-lab-immuno-serologi/:id",
     name: "Data Lab Immuno Serologi",
     component: () => import("@/views/pelayanan/DataLabImmunoSerologi.vue"),
     meta: { auth: true }
    },
   {
     path: "/data-lab-urin/:id",
     name: "Data Lab Urin",
     component: () => import("@/views/pelayanan/DataLabUrin.vue"),
     meta: { auth: true }
    },
  // End Modul Rekam Medis

  // Modul Farmasi
  {
    path: "/aturan-pakai",
    name: "Aturan Pakai",
    component: () => import("@/views/farmasi/AturanPakai.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-aturan-pakai",
    name: "Tambah Aturan Pakai",
    component: () => import("@/views/farmasi/TambahAturanPakai.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-aturan-pakai/:id",
    name: "Edit Aturan Pakai",
    component: () => import("@/views/farmasi/EditAturanPakai.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-obat",
    name: "Data Obat",
    component: () => import("@/views/farmasi/DataObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-obat",
    name: "Tambah Data Obat",
    component: () => import("@/views/farmasi/TambahDataObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-obat/:id",
    name: "Edit Data Obat",
    component: () => import("@/views/farmasi/EditDataObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-percetakan",
    name: "Data Percetakan",
    component: () => import("@/views/farmasi/DataPercetakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-percetakan",
    name: "Tambah Data Percetakan",
    component: () => import("@/views/farmasi/TambahDataPercetakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-percetakan/:id",
    name: "Edit Data Percetakan",
    component: () => import("@/views/farmasi/EditDataPercetakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-alat-kesehatan",
    name: "Data Alat Kesehatan",
    component: () => import("@/views/farmasi/DataAlatKesehatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-alat-kesehatan",
    name: "Tambah Data Alat Kesehatan",
    component: () => import("@/views/farmasi/TambahDataAlatKesehatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-alat-kesehatan/:id",
    name: "Edit Data Alat Kesehatan",
    component: () => import("@/views/farmasi/EditDataAlatKesehatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/racikan-obat",
    name: "Racikan Obat",
    component: () => import("@/views/farmasi/RacikanObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-racikan-obat",
    name: "Tambah Racikan Obat",
    component: () => import("@/views/farmasi/TambahRacikanObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-supplier",
    name: "Data Supplier",
    component: () => import("@/views/farmasi/DataSupplier.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-supplier",
    name: "Tambah Data Supplier",
    component: () => import("@/views/farmasi/TambahDataSupplier.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-supplier/:id",
    name: "Edit Data Supplier",
    component: () => import("@/views/farmasi/EditDataSupplier.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-pelanggan",
    name: "Data Pelanggan",
    component: () => import("@/views/farmasi/DataPelanggan.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-pelanggan",
    name: "Tambah Data Pelanggan",
    component: () => import("@/views/farmasi/TambahDataPelanggan.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-pelanggan/:id",
    name: "Edit Data Pelanggan",
    component: () => import("@/views/farmasi/EditDataPelanggan.vue"),
    meta: { auth: true }
  },
  {
    path: "/pembelian-obat",
    name: "Pembelian Obat",
    component: () => import("@/views/farmasi/PembelianObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-pembelian-obat",
    name: "Tambah Pembelian Obat",
    component: () => import("@/views/farmasi/TambahPembelianObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/list-pembelian-obat",
    name: "List Pembelian Obat",
    component: () => import("@/views/farmasi/ListPembelianObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/pembelian-tambah-obat-baru",
    name: "Pembelian Tambah Obat",
    component: () => import("@/views/farmasi/components/PembelianTambahObatBaru.vue"),
    meta: { auth: true }
  },
  {
    path: "/pemesanan-obat",
    name: "Pemesanan Obat",
    component: () => import("@/views/farmasi/PemesananObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-pemesanan-obat",
    name: "Tambah Pemesanan Obat",
    component: () => import("@/views/farmasi/TambahPemesananObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-pemesanan-obat/:id",
    name: "Edit Pemesanan Obat",
    component: () => import("@/views/farmasi/EditPemesananObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/pemesanan-obat-kegudang",
    name: "Pemesanan Obat Ke Gudang",
    component: () => import("@/views/farmasi/PemesananKeGudang.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-pemesanan-obat-kegudang",
    name: "Tambah Pemesanan Obat Ke Gudang",
    component: () => import("@/views/farmasi/TambahPemesananObatKeGudang.vue"),
    meta: { auth: true }
  },
  {
    path: "/penjualan-obat",
    name: "Penjualan Obat",
    component: () => import("@/views/farmasi/PenjualanObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-penjualan-obat",
    name: "Tambah Penjualan Obat",
    component: () => import("@/views/farmasi/TambahPenjualanObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/penjualan-langsung",
    name: "Penjualan Langsung",
    component: () => import("@/views/farmasi/PenjualanLangsung.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-penjualan-langsung",
    name: "Tambah Penjualan Langsung",
    component: () => import("@/views/farmasi/TambahPenjualanLangsung.vue"),
    meta: { auth: true }
  },
  {
    path: "/kartu-stok",
    name: "Kartu Stok",
    component: () => import("@/views/farmasi/KartuStok.vue"),
    meta: { auth: true }
  },
  {
    path: "/stok-produk",
    name: "Stok Produk",
    component: () => import("@/views/farmasi/StokProduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/stok-obat",
    name: "Stok Obat",
    component: () => import("@/views/farmasi/StokObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/stok-percetakan",
    name: "Stok Percetakan",
    component: () => import("@/views/farmasi/StokPercetakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/stok-alat-kesehatan",
    name: "Stok Alat Kesehatan",
    component: () => import("@/views/farmasi/StokAlatKesehatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/stok-opname",
    name: "Stok Opname",
    component: () => import("@/views/farmasi/StokOpname.vue"),
    meta: { auth: true }
  },
  {
    path: "/lokasi-produk",
    name: "Lokasi Produk",
    component: () => import("@/views/farmasi/Lokasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/faktur-pemesanan/:id",
    name: "faktur pemesanan",
    component: () => import("@/views/farmasi/FakturPemesanan.vue"),
    meta: { auth: true }
  },
  {
    path: "/faktur-penjualan/:id",
    name: "faktur penjualan",
    component: () => import("@/views/farmasi/FakturPenjualan.vue"),
    meta: { auth: true }
  },
  {
    path: "/faktur-pembelian/:id",
    name: "faktur pembelian",
    component: () => import("@/views/farmasi/FakturPembelian.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-detail-produk",
    name: "Data Detail Produk",
    component: () => import("@/views/farmasi/DataDetailProduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/data-produk-kecantikan",
    name: "Data Produk Kecantikan",
    component: () => import("@/views/farmasi/DataProdukKecantikan.vue"),
    meta: { auth: true }
  },
  {
    path: "/produk-terintegrasi-kfa",
    name: "Produk Terintegrasi KFA",
    component: () => import("@/views/farmasi/ProdukTerintegrasiKFA.vue"),
    meta: { auth: true }
  },
  {
    path: "/purchasing-order",
    name: "Purchasing Order",
    component: () => import("@/views/farmasi/PurchasingOrder.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-purchasing-order",
    name: "Tambah Purchasing Order",
    component: () => import("@/views/farmasi/TambahPurchasingOrder.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-purchasing-order/:id",
    name: "Edit Purchasing Order",
    component: () => import("@/views/farmasi/EditPurchasingOrder.vue"),
    meta: { auth: true }
  },
  {
    path: "/sp_reguler/:id",
    name: "SP Reguler",
    component: () => import("@/views/farmasi/SP_Reguler.vue"),
    meta: { auth: true }
  },
  {
    path: "/sp_prekusor/:id",
    name: "SP Prekusor",
    component: () => import("@/views/farmasi/SP_Prekusor.vue"),
    meta: { auth: true }
  },
  {
    path: "/sp_oot/:id",
    name: "Obat-Obat-Tertentu",
    component: () => import("@/views/farmasi/SP_OOT.vue"),
    meta: { auth: true }
  },
  // End Farmasi

  // Modul Pelayanan Klinik

  {
    path: "/resepsionis",
    name: "Resepsionis",
    component: () => import("@/views/pelayanan/Resepsionis.vue"),
    meta: { auth: true }
   },
  {
    path: "/antrian",
    name: "Antrian",
    component: () => import("@/views/pelayanan/DisplayAntrian.vue"),
    meta: { auth: true }
   },
   {
    path: "/appointment-pasien",
    name: "Appointment Pasien",
    component: () => import("@/views/pelayanan/AppointmentPasien.vue"),
    meta: { auth: true }
   },
  {
    path: "/tambah-data-pasien",
    name: "Tambah Data Pasien",
    component: () => import("@/views/pelayanan/TambahDataPasien.vue"),
    meta: { auth: true }
   },
   {
    path: "/perawatan-pemeriksaan-awal",
    name: "Perawatan Pemeriksaan Awal",
    component: () => import("@/views/pelayanan/PerawatanPemeriksaanAwal.vue"),
    meta: { auth: true }
   },
   {
    path: "/perawatan-pemeriksaan-utama",
    name: "Perawatan Pemeriksaan Utama",
    component: () => import("@/views/pelayanan/PerawatanPemeriksaanUtama.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-perawatan/:id",
    name: "Tambah Data Perawatan",
    component: () => import("@/views/pelayanan/TambahDataPerawatan.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-perawatan/:id",
    name: "Edit Data Perawatan",
    component: () => import("@/views/pelayanan/EditDataPerawatan.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-perawatan-kecantikan/:id",
    name: "Edit Data Perawatan Kecantikan",
    component: () => import("@/views/pelayanan/EditDataPerawatanKecantikan.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-perawatan-lab/:id",
    name: "Edit Data Perawatan Lab",
    component: () => import("@/views/pelayanan/EditDataPerawatanLab.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-perawatan-gigi/:id",
    name: "Edit Data Perawatan Gigi",
    component: () => import("@/views/pelayanan/EditDataPerawatanGigi.vue"),
    meta: { auth: true }
   }, 
   {
    path: "/edit-data-perawatan-kia/:id",
    name: "Edit Data Perawatan KIA",
    component: () => import("@/views/pelayanan/EditDataPerawatanKia.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-perawatan-ugd/:id",
    name: "Edit Data Perawatan UGD",
    component: () => import("@/views/pelayanan/EditDataPerawatanUGD.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-lab/:id",
    name: "Tambah Data Lab",
    component: () => import("@/views/pelayanan/TambahDataLab.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-lab-pemeriksaan-penunjang/:id",
    name: "Tambah Data Lab Pemeriksaan Penunjang",
    component: () => import("@/views/pelayanan/TambahDataPemeriksaan.vue"),
    meta: { auth: true }
   },
   {
    path: "/farmasi",
    name: "Farmasi",
    component: () => import("@/views/pelayanan/Farmasi.vue"),
    meta: { auth: true }
   },
   {
    path: "/persediaan-farmasi",
    name: "Persediaan Farmasi",
    component: () => import("@/views/pelayanan/PersediaanFarmasi.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-persediaan-farmasi/:id",
    name: "Tambah Persediaan Farmasi",
    component: () => import("@/views/pelayanan/TambahPersediaanFarmasi.vue"),
    meta: { auth: true }
   },
   {
    path: "/etiket/:id",
    name: "etiket",
    component: () => import("@/views/pelayanan/etiket.vue"),
    meta: { auth: true }
   },
   {
    path: "/etiket-kecantikan/:id",
    name: "Etiket Kecantikan",
    component: () => import("@/views/pelayanan/etiket_kecantikan.vue"),
    meta: { auth: true }
   },
   {
    path: "/data_etiket",
    name: "Data Etiket",
    component: () => import("@/views/pelayanan/Data_Etiket.vue"),
    meta: { auth: true }
   },
   {
    path: "/cetaketiket/:id",
    name: "cetak etiket",
    component: () => import("@/views/pelayanan/cetaketiket.vue"),
    meta: { auth: true }
   },
   {
    path: "/cetaketiket-kct/:id",
    name: "cetak etiket kecantikan",
    component: () => import("@/views/pelayanan/cetaketiketkct.vue"),
    meta: { auth: true }
   },
   {
    path: "/print-telaah-resep/:id",
    name: "print-telaah-resep",
    component: () => import("@/views/pelayanan/cetakTelaahResp.vue"),
    meta: { auth: true }
   },
   {
    path: "/view-jasmed",
    name: "View Jasmed",
    component: () => import("@/views/pelayanan/ViewJasmed.vue"),
    meta: { auth: true }
   },
   {
    path: "/update_etiket/:id",
    name: "update etiket",
    component: () => import("@/views/pelayanan/Update_etiket.vue"),
    meta: { auth: true }
   },
   {
    path: "/update_etiket_kecantikan/:id",
    name: "update etiket kecantikan",
    component: () => import("@/views/pelayanan/Update_etiket_kct.vue"),
    meta: { auth: true }
   },
   {
    path: "/pemeriksaan-lab",
    name: "Pemeriksaan Lab",
    component: () => import("@/views/pelayanan/PemeriksaanLab.vue"),
    meta: { auth: true }
   },
   
   {
    path: "/data-rujukan-lab",
    name: "Data Rujukan Lab",
    component: () => import("@/views/pelayanan/data_rujukan_lab.vue"),
    meta: { auth: true }
   },
   {
    path: "/lab_urin/:id",
    name: "Lab Urin",
    component: () => import("@/views/pelayanan/lab_urin.vue"),
    meta: { auth: true }
   },
   {
    path: "/lab-hematologi-rutin/:id",
    name: "Lab Hematologi Rutin",
    component: () => import("@/views/pelayanan/LabHematologiRutin.vue"),
    meta: { auth: true }
   },
   {
    path: "/lab-immuno-serologi/:id",
    name: "Lab Immuno Serologi",
    component: () => import("@/views/pelayanan/LabImmunoSerologi.vue"),
    meta: { auth: true }
   },
   {
    path: "/lab-kimia-klinik/:id",
    name: "Lab Kimia Klinik",
    component: () => import("@/views/pelayanan/LabKimiaKlinik.vue"),
    meta: { auth: true }
   },
   {
    path: "/lab-paket/:id",
    name: "Lab Paket",
    component: () => import("@/views/pelayanan/LabPaket.vue"),
    meta: { auth: true }
   },
   //penjualan Langsung Farmasi sendang proses
   {
    path: "/farmasi-penjualan-langsung",
    name: "Farmasi Penjualan Langsung",
    component: () => import("@/views/pelayanan/FarmasiPenjualanLangsung.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-penjualan-langsung-pelayanan",
    name: "Tambah Data Penjualan Langsung ",
    component: () => import("@/views/pelayanan/TambahDataPenjualanlangsungFarmasi.vue"),
    meta: { auth: true }
   },
   //end
   {
    path: "/konfirmasi-obat-farmasi/:id",
    name: "Konfirmasi Obat",
    component: () => import("@/views/pelayanan/KonfirmasiObat.vue"),
    meta: { auth: true }
   },
   {
    path: "/konfirmasi-obat-farmasi-prb/:id",
    name: "Konfirmasi Obat PRB",
    component: () => import("@/views/pelayanan/KonfirmasiObatPRB.vue"),
    meta: { auth: true }
   },
   {
    path: "/telaah-resep/:id",
    name: "Pio Farmasii",
    component: () => import("@/views/pelayanan/PioFarmasi.vue"),
    meta: { auth: true }
   },
   {
    path: "/cetak-pio-farmasi/:id",
    name: "Cetak Pio Farmasii",
    component: () => import("@/views/pelayanan/CetakPioFarmasi.vue"),
    meta: { auth: true }
   },
   {
    path: "/kasir",
    name: "Kasir",
    component: () => import("@/views/pelayanan/Kasir.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-pembayaran/:id",
    name: "Pembayaran",
    component: () => import("@/views/pelayanan/TambahDataPembayaran.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-pembayaran-piutang/:id",
    name: "Pembayaran Utang",
    component: () => import("@/views/pelayanan/TambahDataPembayaranPiutang.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-pembayaran-lab/:id",
    name: "Pembayaran Lab",
    component: () => import("@/views/pelayanan/TambahDataPembayaranLab.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-pembayaran-penjualan-langsung/:id",
    name: "Pembayaran Penjualan Langsung",
    component: () => import("@/views/pelayanan/TambahDataPembayaranPenjualanLangsung.vue"),
    meta: { auth: true }
   },
   {
    path: "/transaksi-perawatan",
    name: "Transaksi Perawatan",
    component: () => import("@/views/pelayanan/TransaksiPerawatan.vue"),
    meta: { auth: true }
   },
   {
     path: "/print-transaksi-perawatan/:id",
     name: "Print Struk",
     component: () => import("@/views/pelayanan/PrintStruk.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-transaksi-penjulan-langsung/:id",
     name: "Print Struk Penjualan Langsung",
     component: () => import("@/views/pelayanan/PrintStrukLangsung.vue"),
     meta: { auth: true }
    },
   {
     path: "/antrian-pasien/:id",
     name: "Print Antrian",
     component: () => import("@/views/pelayanan/PrintAntrian.vue"),
     meta: { auth: true }
    },
   {
     path: "/antrian-pasien-dokter/:id",
     name: "Print Antrian Ke Dokter",
     component: () => import("@/views/pelayanan/PrintAntrianDokter.vue"),
     meta: { auth: true }
    },
   {
     path: "/antrian-pasien-farmasi/:id",
     name: "Print Antrian Farmasi",
     component: () => import("@/views/pelayanan/PrintAntrianFarmasi.vue"),
     meta: { auth: true }
    },
   {
     path: "/pio-farmasi",
     name: "Pio Farmasi",
     component: () => import("@/views/pelayanan/Pio.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-pembayaran-piutang/:id",
     name: "Print Struk Piutang",
     component: () => import("@/views/pelayanan/PrintStrukPembayaranPiutang.vue"),
     meta: { auth: true }
    },
   {
     path: "/hak-dan-kewajiban/:id",
     name: "hak dan kewajiban",
     component: () => import("@/views/pelayanan/Hakdankewajiban.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-hak-dan-kewajiban/:id",
     name: "Print hak dan kewajiban",
     component: () => import("@/views/pelayanan/PrintHakdankewajiban.vue"),
     meta: { auth: true }
    },
   {
     path: "/informed-concent/:id",
     name: "informed concent",
     component: () => import("@/views/pelayanan/informed_concent.vue"),
     meta: { auth: true }
    },
   {
     path: "/general-consent/:id",
     name: "Genera Konsent ",
     component: () => import("@/views/pelayanan/general_consent.vue"),
     meta: { auth: true }
    },
    {
      path: "/view-general-consent/:id",
      name: "View General Consent ",
      component: () => import("@/views/pelayanan/ViewGeneralConsent.vue"),
      meta: { auth: true }
     },
    {
      path: "/data-view-general-consent/:id",
      name: "Data View General Consent ",
      component: () => import("@/views/pelayanan/DataViewGeneralConsent.vue"),
      meta: { auth: true }
     },
   {
     path: "/detail-general-consent/:id",
     name: "Detail General Konsent ",
     component: () => import("@/views/rekam_medis/detail_generak_consent.vue"),
     meta: { auth: true }
    },
   {
     path: "/detail-persetujuan-bayar/:id",
     name: "Detail Persetujuan Bayar ",
     component: () => import("@/views/rekam_medis/detail_persetujuan_bayar.vue"),
     meta: { auth: true }
    },
   {
     path: "/detail-telaah-resep/:id",
     name: "Detail Telaah Resep",
     component: () => import("@/views/rekam_medis/detail_telaah_resep.vue"),
     meta: { auth: true }
    },
   {
     path: "/view-persetujuan-bayar/:id",
     name: "Data View Persetujuan Bayar",
     component: () => import("@/views/pelayanan/DataViewPersetujuanBayar.vue"),
     meta: { auth: true }
    },
   {
     path: "/view-general-consent/:id",
     name: "View General Consent ",
     component: () => import("@/views/pelayanan/ViewGeneralConsent.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-informed-concent/:id",
     name: "Informed Concent",
     component: () => import("@/views/pelayanan/informedConcent.vue"),
     meta: { auth: true }
    },
   {
     path: "/edukasi-gizi/:id",
     name: "edukasi gizi.",
     component: () => import("@/views/pelayanan/edukasi_gizi.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-edukasi-gizi/:id",
     name: "print edukasi gizi.",
     component: () => import("@/views/pelayanan/print_edukasi_gizi.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-transaksi-perawatan-lab/:id",
     name: "Print Struk Lab",
     component: () => import("@/views/pelayanan/PrintStrukLab.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-resep/:id",
     name: "Print Resep",
     component: () => import("@/views/pelayanan/PrintStrukFarmasi.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-resep-copy/:id",
     name: "Print Resep Copy",
     component: () => import("@/views/pelayanan/Printresepcopy.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-resep-prb/:id",
     name: "Print Resep PRB",
     component: () => import("@/views/pelayanan/PrintresepPRB.vue"),
     meta: { auth: true }
    },
   {
     path: "/pembayaran-piutang",
     name: "Pembayaran Piutang",
     component: () => import("@/views/pelayanan/PembayaranPiutang.vue"),
     meta: { auth: true }
    },
   {
     path: "/print-kartu-stok/:id",
     name: "Print Kartu Stok",
     component: () => import("@/views/pelayanan/PrintKartuStok.vue"),
     meta: { auth: true }
    },
    {
     path: "/surat",
     name: "Surat",
     component: () => import("@/views/pelayanan/Surat.vue"),
     meta: { auth: true }
    },
    {
     path: "/ruang-periksa",
     name: "Ruang Periksa",
     component: () => import("@/views/pelayanan/RuangPeriksa.vue"),
     meta: { auth: true }
    },
    {
     path: "/tambah-ruang-periksa",
     name: "Tambah Ruang Periksa",
     component: () => import("@/views/pelayanan/TambahRuangPeriksa.vue"),
     meta: { auth: true }
    },
    {
     path: "/edit-ruang-periksa/:id",
     name: "Edit Ruang Periksa",
     component: () => import("@/views/pelayanan/TambahRuangPeriksa.vue"),
     meta: { auth: true }
    },
    {
     path: "/satu-sehat",
     name: "Satu Sehat",
     component: () => import("@/views/pelayanan/SatuSehat.vue"),
     meta: { auth: true }
    },
    {
     path: "/ihs_not_integrated",
     name: "Satu Sehat Nik Belum terintegrasi",
     component: () => import("@/views/pelayanan/ihs_not_integrated.vue"),
     meta: { auth: true }
    },
  // End Pelayanan Klinik

  // AKUNTANSI
   {
    path: "/data-akun-keuangan",
    name: "Data Akun Keuangan",
    component: () => import("@/views/akuntansi/DataAkunKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/data-jurnal-keuangan",
    name: "Data Jurnal Keuangan",
    component: () => import("@/views/akuntansi/DataJurnalKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/data-jurnal-singkat",
    name: "Data Jurnal Singkat",
    component: () => import("@/views/akuntansi/DataJurnalSingkat.vue"),
    meta: { auth: true }
   },
   {
    path: "/data-budgeting-keuangan",
    name: "Data Budgeting Keuangan",
    component: () => import("@/views/akuntansi/DataBudgetingKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-akun-keuangan",
    name: "Tambah Data Akun Keuangan",
    component: () => import("@/views/akuntansi/TambahDataAkunKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-akun-keuangan/:id",
    name: "Edit Data Akun Keuangan",
    component: () => import("@/views/akuntansi/EditDataAkunKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-jurnal-keuangan",
    name: "Tambah Data Jurnal Keuangan",
    component: () => import("@/views/akuntansi/TambahDataJurnalKeuangan.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-data-jurnal-singkat",
    name: "Tambah Data Jurnal Singkat",
    component: () => import("@/views/akuntansi/TambahDataJurnalSingkat.vue"),
    meta: { auth: true }
   },
  // END AKUNTANSI
  
  // HRD
  {
    path: "/data-karyawan",
    name: "Data Karyawan",
    component: () => import("@/views/hrd/DataKaryawan.vue"),
    meta: { auth: true }
   },
 
   {
    path: "/tambah-data-karyawan",
    name: "Tambah Data Karyawan",
    component: () => import("@/views/hrd/TambahDataKaryawan.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-data-karyawan/:id",
    name: "Edit Data Karyawan",
    component: () => import("@/views/hrd/EditDataKaryawan.vue"),
    meta: { auth: true }
   },
   {
    path: "/absensi-karyawan",
    name: "Absensi Karyawan",
    component: () => import("@/views/hrd/AbsensiKaryawan.vue"),
    meta: { auth: true }
   },
   {
    path: "/tambah-jadwal-absen",
    name: "Tambah Jadwal Absen",
    component: () => import("@/views/hrd/TambahJadwalAbsen.vue"),
    meta: { auth: true }
   },
   {
    path: "/edit-jadwal-absen/:id",
    name: "Edit Jadwal Absen",
    component: () => import("@/views/hrd/EditJadwalAbsen.vue"),
    meta: { auth: true }
   },
   {
    path: "/pengaturan-jadwal",
    name: "Pengaturan Jadwal",
    component: () => import("@/views/hrd/PengaturanJadwal.vue"),
    meta: { auth: true }
   },
   {
    path: "/view-jadwal-karyawan",
    name: "View Jadwal Karyawan",
    component: () => import("@/views/hrd/ViewJadwalKaryawan.vue"),
    meta: { auth: true }
   },
   {
    path: "/view-jadwal-dokter",
    name: "View Jadwal Dokter",
    component: () => import("@/views/hrd/ViewJadwalDokter.vue"),
    meta: { auth: true }
   },
   {
    path: "/monitoring-hrd",
    name: "Monitoring HRD",
    component: () => import("@/views/hrd/MonitoringHRD.vue"),
    meta: { auth: true }
   },
   {
    path: "/view-profil/:id",
    name: "View Profil",
    component: () => import("@/views/hrd/viewProfil.vue"),
    meta: { auth: true }
   },
  // END HRD
  
  // LAPORAN - LAPORAN
  {
    path: "/laporan-pelayanan-klinik-staff",
    name: "Laporan Pelayanan Klinik staff",
    component: () => import("@/views/laporan/LaporanPelayananKlinik.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pelayanan-klinik-admin",
    name: "Laporan Pelayanan Klinik admin",
    component: () => import("@/views/laporan/LaporanPelayananKlinikadmin.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-rekam-medis",
    name: "Laporan Rekam Medis",
    component: () => import("@/views/laporan/LaporanRekamMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-farmasi",
    name: "Laporan Farmasi",
    component: () => import("@/views/laporan/LaporanFarmasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-penjualan-obat-tertinggi",
    name: "Penjualan Obat Tertinggi",
    component: () => import("@/views/laporan/PenjualanObatTertinggi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-akuntansi",
    name: "Laporan Akuntansi",
    component: () => import("@/views/laporan/LaporanAkuntansi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-hrd",
    name: "Laporan HRD",
    component: () => import("@/views/laporan/LaporanHrd.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pendapatan-harian",
    name: "Laporan Pendapatan Harian",
    component: () => import("@/views/laporan/LaporanPendapatanHarian.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-transaksi-per-periode",
    name: "Laporan Transaksi Per Periode",
    component: () => import("@/views/laporan/LaporanTransaksiPerPeriode.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-perawatan-per-periode",
    name: "Laporan Perawatan Per Periode",
    component: () => import("@/views/laporan/LaporanPerawatanPerPeriode.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-per-jenis-bayar",
    name: "Laporan Per Jenis Bayar",
    component: () => import("@/views/laporan/LaporanPerJenisBayar.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-tindakan-per-periode",
    name: "Laporan Tindakan Per Periode",
    component: () => import("@/views/laporan/LaporanTindakanPerPeriode.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-antrian-pasien",
    name: "Laporan Antrian Pasien",
    component: () => import("@/views/laporan/LaporanAntrianPasien.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-harian",
    name: "Laporan Harian",
    component: () => import("@/views/laporan/LaporanHarian.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-jasa-medis",
    name: "Laporan Jasa Medis",
    component: () => import("@/views/laporan/LaporanJasaMedis.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-rekapitulasi",
    name: "Laporan Rekapitulasi",
    component: () => import("@/views/laporan/LaporanRekapitulasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-tutup-buku/:id",
    name: "Laporan Tutup Buku",
    component: () => import("@/views/laporan/LaporanTutupBuku.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pengeluaran",
    name: "Laporan Pengeluaran",
    component: () => import("@/views/laporan/LaporanPengeluaran.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-pengeluaran",
    name: "Tambah Data Pengeluaran",
    component: () => import("@/views/laporan/components/TambahDataPengeluaran.vue"),
    meta: { auth: true }
  },
  {
    path: "/edit-data-pengeluaran/:id",
    name: "Edit Data Pengeluaran",
    component: () => import("@/views/laporan/components/EditDataPengeluaran.vue"),
    meta: { auth: true }
  },
  {
    path: "/print-penyerahan-uang/:id",
    name: "Print Penyerahan Uang",
    component: () => import("@/views/laporan/components/PrintPenyerahanUang.vue"),
    meta: { auth: true }
  },
  {
    path: "/rincian-fee-beautician",
    name: "Rincian Fee Beautician",
    component: () => import("@/views/laporan/RincianFeeBeautician.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekapitulasi-fee-beautician",
    name: "Rekapitulasi Fee Beautician",
    component: () => import("@/views/laporan/RekapitulasiFeeBeautician.vue"),
    meta: { auth: true }
  },
  {
    path: "/rincian-fee-dokter",
    name: "Rincian Fee Dokter",
    component: () => import("@/views/laporan/RincianFeeDokter.vue"),
    meta: { auth: true }
  },
  {
    path: "/rekapitulasi-fee-dokter",
    name: "Rekapitulasi Fee Dokter",
    component: () => import("@/views/laporan/RekapitulasiFeeDokter.vue"),
    meta: { auth: true }
  },
  {
    path: "/penjualan-produk-per-transaksi",
    name: "Penjualan Produk Per Transaksi",
    component: () => import("@/views/laporan/PenjualanProdukPerTransaksi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pembelian-obat",
    name: "Laporan Pembelian Obat",
    component: () => import("@/views/laporan/LaporanPembelianObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-penerimaan-stok",
    name: "Laporan Penerimaan Stok",
    component: () => import("@/views/laporan/LaporanPenerimaanStok.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-mutasi-obat",
    name: "Laporan Mutasi Obat",
    component: () => import("@/views/laporan/LaporanMutasiObat.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-stok-produk",
    name: "Laporan Stok Produk",
    component: () => import("@/views/laporan/LaporanStokProduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/produk-terjual-per-periode",
    name: "Laporan Produk Terjual Per Periode",
    component: () => import("@/views/laporan/LaporanProdukTerjualPerPeriode.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-stok-opname",
    name: "Laporan Stok Opname",
    component: () => import("@/views/laporan/LaporanStokOpname.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-mutasi-stok-opname",
    name: "Laporan Mutasi Stok Opname",
    component: () => import("@/views/laporan/LaporanMutasiStokOpname.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-obat-expired",
    name: "Laporan Obat Expired",
    component: () => import("@/views/laporan/LaporanObatExpired.vue"),
    meta: { auth: true }
  },
  {
    path: "/jurnal-harian",
    name: "Jurnal Harian",
    component: () => import("@/views/laporan/jurnalHarian.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-jurnal-keuangan",
    name: "Laporan Jurnal Keuangan",
    component: () => import("@/views/laporan/LaporanJurnalKeuangan.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-buku-besar",
    name: "Laporan Buku Besar",
    component: () => import("@/views/laporan/LaporanBukuBesar.vue"),
    meta: { auth: true, pusat: true }
  },
  {
    path: "/laporan-neraca",
    name: "Laporan Neraca",
    component: () => import("@/views/laporan/LaporanNeraca.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-laba-rugi",
    name: "Laporan Laba Rugi",
    component: () => import("@/views/laporan/LaporanLabaRugi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-perubahan-modal",
    name: "Laporan Perubahan Modal",
    component: () => import("@/views/laporan/LaporanPerubahanModal.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-budgeting",
    name: "Laporan Budgeting",
    component: () => import("@/views/laporan/LaporanBudgeting.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-expired-kontrak-karyawan",
    name: "Laporan Expired Kontrak Karyawan",
    component: () => import("@/views/laporan/LaporanExpiredKontrakKaryawan.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-str",
    name: "Laporan Str",
    component: () => import("@/views/laporan/LaporanStr.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-appointment-pasien",
    name: "Laporan Appointment Pasien",
    component: () => import("@/views/laporan/LaporanAppointmentPasien.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-kurang-bayar",
    name: "Laporan Kurang Bayar",
    component: () => import("@/views/laporan/LaporanKurangBayar.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-diagnosa",
    name: "Laporan Diagnosa",
    component: () => import("@/views/laporan/LaporanDiagnosa.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-penyakit",
    name: "Laporan Penyakit",
    component: () => import("@/views/laporan/LaporanPenyakit.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-pembayaran-piutang",
    name: "Laporan Pembayaran Piutang",
    component: () => import("@/views/laporan/LaporanPembayaranPiutang.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-diagnosa-tertinggi",
    name: "Laporan Diagnosa Tertinggi",
    component: () => import("@/views/laporan/LaporanDiagnosaTertinggi.vue"),
    meta: { auth: true }
  },
  {
    path: "/laporan-rujukan",
    name: "Laporan Rujukan",
    component: () => import("@/views/laporan/LaporanRujukan.vue"),
    meta: { auth: true }
  },
  {
    path: "/transaksi-per-periode",
    name: "Transaksi Per Periode",
    component: () => import("@/views/laporan/TransaksiPerPeriode.vue"),
    meta: { auth: true }
  },
  
  // END LAPORAN - LAPORAN

  // ANALISIS & STATISTIK
  {
    path: "/analisis-traffic",
    name: "traffic",
    component: () => import("@/views/analisis/Traffic.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-revenue",
    name: "revenue",
    component: () => import("@/views/analisis/Revenue.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-demografi",
    name: "demografi",
    component: () => import("@/views/analisis/Demografi.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-jenis-kelamin",
    name: "analisis jenis kelamin",
    component: () => import("@/views/analisis/AnalisisJenisKelamin.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-usia",
    name: "analisis usia",
    component: () => import("@/views/analisis/AnalisisUsia.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-pekerjaan",
    name: "analisis pekerjaan",
    component: () => import("@/views/analisis/AnalisisPekerjaan.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-sumber-informasi",
    name: "analisis sumber informasi",
    component: () => import("@/views/analisis/AnalisisSumberInformasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-sosial-media",
    name: "analisis sosial media",
    component: () => import("@/views/analisis/AnalisisSosialMedia.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-lokasi",
    name: "analisis lokasi",
    component: () => import("@/views/analisis/AnalisisLokasi.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-kunjungan-pasien",
    name: "analisis kunjungan pasien",
    component: () => import("@/views/analisis/AnalisisKunjunganPasien.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-konsultasi-dokter",
    name: "analisis konsultasi dokter",
    component: () => import("@/views/analisis/AnalisisKonsultasiDokter.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-pendapatan",
    name: "analisis pendapatan",
    component: () => import("@/views/analisis/AnalisisPendapatan.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-pendapatan-tindakan",
    name: "analisis pendapatan tindakan",
    component: () => import("@/views/analisis/AnalisisPendapatanTindakan.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-pendapatan-produk",
    name: "analisis pendapatan produk",
    component: () => import("@/views/analisis/AnalisisPendapatanProduk.vue"),
    meta: { auth: true }
  },
  {
    path: "/analisis-pendapatan-administrasi",
    name: "analisis pendapatan administrasi",
    component: () => import("@/views/analisis/AnalisisPendapatanAdministrasi.vue"),
    meta: { auth: true }
  },
  // END ANALISIS & STATISTIK
  
  // MEMBERSHIP
  {
    path: "/data-membership",
    name: "Data Membership",
    component: () => import("@/views/membership/DataMembership.vue"),
    meta: { auth: true }
  },
  {
    path: "/tambah-data-membership",
    name: "Tambah Data Membership",
    component: () => import("@/views/membership/TambahMembership.vue"),
    meta: { auth: true }
  },
  // END MEMBERSHIP

  // CHAT
  {
    path: "/chat-list",
    name: "Chat List",
    component: () => import("@/views/chat/ChatList.vue"),
    meta: { auth: true }
  },
  // END CHAT
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && store.getters['auth/authenticated']) {
    if (to.meta.pusat && store.getters['auth/cabang']['jenis'] != 'pusat') {
      router.push('/')
    }
  }
  if (to.meta.auth && !store.getters['auth/authenticated']) router.push('/login')
  if (to.meta.guest && store.getters['auth/authenticated']) router.push('/')

  next()
})


export default router
