import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    jadwalShift: null,
    loading: null,
  },
  getters: {
    getJadwalShift(state) {
      return state.jadwalShift;
    },
    getLoading(state) {
      return state.loading;
    },
    getJadwalShiftByDivisi: (state) => (nama_divisi) => {
      if (state.jadwalShift && Array.isArray(state.jadwalShift)) {
        return state.jadwalShift.filter((shift) => shift.nama_divisi === nama_divisi);
      }
      return [];
    }
  },
  mutations: {
    setJadwalShift(state, value) { 
      state.jadwalShift = value;
    },
    setLoading(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async fetchJadwalShift({ commit }, id) {
      commit("setLoading", true);
      await axios.get(`jadwal_shift/get/${id}`)
        .then((response) => {
          commit("setJadwalShift", response.data.data ? response.data.data : null);
        })
        .catch(() => {
          commit("setJadwalShift", null);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    async fetchJadwalShiftById({ commit }, id) {
      commit("setLoading", true);
      await axios.get(`jadwal_shift/getById/${id}`)
        .then((response) => {
          commit("setJadwalShift", response.data ? response.data : null);
        })
        .catch(() => {
          commit("setJadwalShift", null);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    async fetchJadwalShiftByDivisi({ commit }, { tbCabangId, nama_divisi }) {
      commit("setLoading", true);
      console.log('Calling API: /jadwal_shift/getbydivisi', { tbCabangId, nama_divisi });
      await axios.get(`jadwal_shift/getbydivisi/${tbCabangId}/${nama_divisi}`)
        .then((response) => {
          commit("setJadwalShift", response.data.data ? response.data.data : null);
        })
        .catch(() => {
          commit("setJadwalShift", null);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    resetData({ commit }) {
      commit("setJadwalShift", null);
    }
  }
});
